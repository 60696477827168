import React from 'react';
import { graphql } from 'gatsby';

import Layout from 'components/Layout';
import KenraLanding from 'components/KenraLanding';

export default function KenraLandingPage(props) {
  let {
    data: { contentfulKenraLanding },
  } = props;

  return (
    <Layout>
      <KenraLanding page={contentfulKenraLanding} />
    </Layout>
  );
}

export const query = graphql`
  query {
    contentfulKenraLanding {
      id
      image1 {
        localFile {
          publicURL
        }
      }
      heroSlider {
        ...SlideFields
      }
      image1Text
      image1Title
      image1Url
      image3Text
      image3Title
      image3Url
      image3 {
        localFile {
          publicURL
        }
      }
      image2Text
      image2Title
      image2Url
      image2 {
        localFile {
          publicURL
        }
      }
      title
      text {
        json
      }
      stylistFavorites {
        handle
      }
      stylistFavoritesViewAllLink
      latestTutorials {
        ...ContentfulVideoFields
      }
    }
  }
`;
