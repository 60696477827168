import React from 'react';
import get from 'lodash.get';
import { documentToReactComponents } from '@contentful/rich-text-react-renderer';

import ProductSlider from 'blocks/ProductSlider';
import HeroSlider from 'components/HeroSlider';
import urls from 'helpers/url';
import { multiline } from 'helpers/text';
import { getYouTubeId, getPreviewUrl, getEmbedUrl } from 'helpers/youtube';

import {
  Spacing,
  SharedStyles,
  Container,
  ImageTextGrid,
  VideoRow,
} from '@matchbox-mobile/kenra-storybook';

const {
  StSectionInfo,
  StTitle,
  StLinkMore,
  StSectionMobileViewMore,
  StSectionTitle,
  StLink,
} = SharedStyles;

export default function KenraLanding({ page }) {
  let { latestTutorials } = page;

  return (
    <>
      <Spacing removeSpaceTop>
        <HeroSlider slides={page.heroSlider} />
      </Spacing>

      <Spacing removeSpaceBottomMobile>
        <StSectionInfo useMobileBg>
          <Container>
            <StTitle>
              <h2>{page.title}</h2>
            </StTitle>
            {documentToReactComponents(page.text.json)}
            <StLinkMore styleBig>
              <a href="/collection/kenra">VIEW ALL PRODUCTS</a>
            </StLinkMore>
          </Container>
        </StSectionInfo>
      </Spacing>

      <Spacing>
        <ImageTextGrid
          items={[
            {
              img: get(page, 'image1.localFile.publicURL'),
              title: page.image1Title,
              text: page.image1Text,
              linkText: 'VIEW ALL PRODUCTS',
              linkHref: page.image1Url,
              linkStyleWhite: true,
            },
            {
              img: get(page, 'image2.localFile.publicURL'),
              title: page.image2Title,
              text: page.image2Text,
              linkText: 'VIEW ALL PRODUCTS',
              linkHref: page.image2Url,
              linkStyleWhite: true,
            },
            {
              img: get(page, 'image3.localFile.publicURL'),
              title: page.image3Title,
              text: page.image3Text,
              linkText: 'VIEW ALL PRODUCTS',
              linkHref: page.image3Url,
              linkStyleWhite: true,
            },
          ]}
        />
      </Spacing>

      <Spacing>
        <ProductSlider
          title="STYLIST FAVORITES"
          collectionHandle={get(page, 'stylistFavorites.handle')}
          bigTitle
          viewAllLink={get(page, 'stylistFavoritesViewAllLink')}
        />
      </Spacing>

      {latestTutorials && (
        <Spacing>
          <StSectionMobileViewMore>
            <StSectionTitle>
              <Container>
                <h2>Latest Tutorials</h2>
                <StLink>
                  <a
                    href={urls.videos}
                  >{`VIEW ALL (${latestTutorials.length})`}</a>
                </StLink>
              </Container>
            </StSectionTitle>
            <Container>
              <VideoRow
                items={latestTutorials.map(video => {
                  let youTubeId = getYouTubeId(video.youtubeLink);
                  return {
                    title: multiline(get(video, 'title.title')),
                    text: get(video, 'description'),
                    btnSizeMedium: true,
                    bgImg: getPreviewUrl(youTubeId),
                    videoType: 'youtube',
                    autoPlay: true,
                    videoOptions: [{ src: getEmbedUrl(youTubeId) }],
                  };
                })}
              />
            </Container>
          </StSectionMobileViewMore>
        </Spacing>
      )}
    </>
  );
}
